<template>
  <Container>
    <RichText :content="text" />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    name: "AlchemyTextBlock",
    mixins: [AlchemyElement],
    computed: {
      text() {
        return this.getRichtext("text")
      },
    },
  }
</script>
